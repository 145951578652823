import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import "../../components/layout.css"
import SEO from "../../components/seo"
import IA from "../../images/image-asset.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const CoachingEngage = () => (
	<Layout>
		<SEO
			title="Coaching Engagement Program — AGL (719-800-2149)"
			description="Welcome to our amazing support network and resources system by coaches, for coaches. We are excited to support you in growing as a coach and in growing your business. When you sign up, each month you will receive:   - Personalized login to an online community of encouragement, award-giving, resource"
		/>
		<div className="page-headline">
			<div className="container">
				<div className="section-heading text-center">
					<h6 className="font-weight-bold text-uppercase flair">
						COACHING ENGAGEMENT PROGRAM
					</h6>
				</div>
			</div>
		</div>
		<div className="page-content coaching-engage">
			<div class="container">
				<div className="d-flow-root">
					<div className="text-left d-inline-flex">
						<Link to="/resources">RESOURCES</Link>

						<span className="mx-2">></span>

						<Link to="/resources/f250aeenb0azh81jco71e0nwnhjm2q">
							COACHING ENGAGEMENT PROGRAM
						</Link>
					</div>
					<div className="d-inline-flex float-right mt-2">
						<Link to="/resources/w23oadkqeru3ts1j7mt96e82ztt531">PREVIOUS</Link>
						<span className="mx-2">></span>
					</div>
				</div>
				<div className="row justify-content-between pt-5">
					<div className="col-md-4  pb-5 pb-md-0">
						<div className="mastering-content">
							<h2>COACHING ENGAGEMENT PROGRAM</h2>
							<span>$75.00 every month</span>
							<p className="mt-3">
								Welcome to our amazing support network and resources system by
								coaches, for coaches. We are excited to support you in growing
								as a coach and in growing your business. When you sign up, each
								month you will receive:
							</p>
							<p>
								- Personalized login to an online community of encouragement,
								award-giving, resource-sharing, and cheering each on as we build
								our businesses.
							</p>
							<p>
								- Exclusive coach-only resource page! Material will be added
								each month.
							</p>
							<p>
								- Monthly team calls around coaching concepts to keep you
								learning and engaged.
							</p>
							<p>
								- Ebooks, business training sessions, and more throughout each
								month.
							</p>
						</div>

						<a href="/contact" className="team-btn mt-3 justify-content-center">
							Purchase
						</a>
					</div>
					<div className="col-md-8 text-center pb-5 pb-md-0">
						<div>
							<LazyLoadImage
								className="img-fluid"
								src={IA}
								alt="Coaching Engagement Program"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<section id="information" className="pt-0">
			<div className="container">
				<div className="col">
					<h4>CONTACT INFORMATION:</h4>
					<p>impact@coachingforgood.org</p>
					<p>719.800.2149</p>
				</div>
			</div>
		</section>
	</Layout>
)

export default CoachingEngage
